import {
  deleteResourceRequestConfig,
  getResourceRequestConfig,
  modifyResourceRequestConfig,
} from 'Components/Hooks/_types_/UseBestApiRequestConfig';
import { properties } from 'Helpers/properties';

const branchNumber = sessionStorage.getItem('userServiceBranch')
  ? sessionStorage.getItem('userServiceBranch') + '/'
  : '';

export const userServiceApiBaseUrl = (path: string): string =>
  `${properties.serviceApiURL}${branchNumber}user/${path}`;

export type GetLinkedAccount = getResourceRequestConfig & {
  method: 'GET';
};

export type PutLinkedAccount = modifyResourceRequestConfig & {
  method: 'PUT';
};

export type PostLinkedAccount = modifyResourceRequestConfig<{
  bi2NetworkId: string | undefined;
}> & {
  method: 'POST';
};

export type DeleteLinkedAccount = deleteResourceRequestConfig & {
  method: 'DELETE';
};
