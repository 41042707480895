import { useCallback, useEffect, useState } from 'react';

import { SET_AD_ACCOUNTS } from 'Hooks/useAuthHandler/_constants_/authHandlerConstants';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { getLinkedProfiles } from 'UserAuthentication/_LoginUserServices/hooks/functionalities/getLinkedProfiles';

export const useFetchGetLinkedProfiles = () => {
  const { authState, authDispatch } = useAuthState();
  const [loading, setLoading] = useState(false);
  const [fetchedForToken, setFetchedForToken] = useState<string | undefined>();

  const refetch = useCallback((): Promise<void> => {
    if (authState.tokens?.accessToken === undefined) {
      return Promise.reject(new Error('useFetchAllAdAccounts: No access token'));
    }
    setLoading(true);

    setFetchedForToken(authState.tokens.accessToken);
    return getLinkedProfiles(authState.tokens.accessToken)
      .then((res) => {
        authDispatch({
          type: SET_AD_ACCOUNTS,
          adAccounts: res.data ?? undefined,
        });
        return Promise.resolve();
      })
      .catch((e) => {
        authDispatch({
          type: SET_AD_ACCOUNTS,
          adAccounts: undefined,
        });
        return Promise.reject(new Error(e));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [authDispatch, authState.tokens?.accessToken]);

  useEffect(() => {
    if (
      authState.profiles !== undefined ||
      authState.tokens?.accessToken === undefined ||
      fetchedForToken === authState.tokens.accessToken ||
      loading
    ) {
      return;
    }
    setFetchedForToken(authState.tokens.accessToken);
    refetch();
  }, [authState.profiles, authState.tokens?.accessToken, fetchedForToken, loading, refetch]);

  return {
    refetch,
  };
};
