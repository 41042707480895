import { TableFilterItems } from 'Components/Shared/TableFilter/TableFilter';
import { OrderTypeFilter } from 'Orders/_types_/OrderTypeFilter';
import {
  DataCacheActionsTypes,
  SET_LOADING_VENDORS,
  SetDataChacheLoading,
  SetVendors,
  SET_VENDORS,
  SetError,
  SET_VENDORS_ERROR,
  SET_ORDER_TYPES,
  SetOrderTypeFilters,
  SET_ORDER_TYPES_LOADING,
  SET_ORDER_TYPES_ERROR,
  SET_LOADING_PRODUCT_LINES,
  SET_PRODUCT_LINES_ERROR,
  SET_PRODUCT_LINES,
  SetProductLines,
} from 'Store/actions/dataCache.actions';

export interface CachedData {
  loading: boolean;
  lastFetch: Date | undefined;
  error: string | undefined;
}

export interface Vendor {
  vendorName: string;
  vendorMdmIdentifier: string | number;
}

export interface ProductLineCache extends CachedData {
  productLines: TableFilterItems[];
}

export interface OrderTypeFilterCache extends CachedData {
  orderTypeFilters: OrderTypeFilter[];
}

export interface DataCacheState {
  vendors: { vendors: Vendor[] } & CachedData;
  productLines: ProductLineCache;
  orderTypeFilters: OrderTypeFilterCache;
}

const initialState: DataCacheState = {
  vendors: {
    vendors: [],
    loading: false,
    lastFetch: undefined,
    error: undefined,
  },
  productLines: {
    productLines: [],
    loading: false,
    lastFetch: undefined,
    error: undefined,
  },
  orderTypeFilters: {
    error: undefined,
    lastFetch: undefined,
    loading: false,
    orderTypeFilters: [],
  },
};

export default (state = initialState, action: DataCacheActionsTypes): DataCacheState => {
  switch (action.type) {
    case SET_ORDER_TYPES: {
      return {
        ...state,

        orderTypeFilters: {
          orderTypeFilters: (action as SetOrderTypeFilters).orderTypeFilters,
          loading: false,
          lastFetch: new Date(Date.now()),
          error: undefined,
        },
      };
    }
    case SET_ORDER_TYPES_LOADING: {
      return {
        ...state,
        orderTypeFilters: {
          ...state.orderTypeFilters,
          loading: (action as SetDataChacheLoading).loading,
        },
      };
    }
    case SET_ORDER_TYPES_ERROR: {
      return {
        ...state,
        orderTypeFilters: {
          ...state.orderTypeFilters,
          loading: false,
          error: (action as SetError).message,
        },
      };
    }
    case SET_VENDORS: {
      return {
        ...state,

        vendors: {
          vendors: (action as SetVendors).vendors,
          loading: false,
          lastFetch: new Date(Date.now()),
          error: undefined,
        },
      };
    }
    case SET_LOADING_VENDORS: {
      return {
        ...state,
        vendors: {
          ...state.vendors,
          loading: (action as SetDataChacheLoading).loading,
        },
      };
    }
    case SET_VENDORS_ERROR: {
      return {
        ...state,
        vendors: {
          ...state.vendors,
          loading: false,
          error: (action as SetError).message,
        },
      };
    }
    case SET_PRODUCT_LINES: {
      return {
        ...state,
        productLines: {
          productLines: (action as unknown as SetProductLines).productLines,
          loading: false,
          lastFetch: new Date(Date.now()),
          error: undefined,
        },
      };
    }
    case SET_LOADING_PRODUCT_LINES: {
      return {
        ...state,
        productLines: {
          ...state.productLines,
          loading: (action as SetDataChacheLoading).loading,
        },
      };
    }
    case SET_PRODUCT_LINES_ERROR: {
      return {
        ...state,
        productLines: {
          ...state.productLines,
          loading: false,
          error: (action as SetError).message,
        },
      };
    }
    default: {
      return state;
    }
  }
};
