import React, { ReactElement, Suspense, useEffect, useState, useMemo, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { useReduxDispatch } from 'Helpers';
import { theme } from 'Theme';

import { Loading } from '@bestseller-bit/frontend-community.components.loading';
import Campaign from 'App/Campaign';
import Papskilt from 'App/Papskilt';
import PromptToRefresh from 'App/PromptToRefresh';
import VpFooter from 'Components/UI/Devbar';
import DisplayErrorHandler from 'Components/UI/DisplayErrorHandler';
import ErrorBoundary from 'Components/UI/ErrorBoundary';
import GreetingDialogs from 'Components/UI/GreetingsDialog/GreetingDialogs';
import Navigation from 'Components/UI/Navigation';
import Splash from 'Components/UI/Splash';
import 'globals.css';
import useAxiosInterceptors from 'Hooks/useAuthHandler';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import useDDAuth from 'Hooks/useDDAuth';
import useLDAuth from 'Hooks/useLDAuth';
import { SampleProformaHeaderContext } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderContext';
import { useSampleProformaHeaderReducer } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/useSampleProformaHeaderReducer';
import { User } from 'Models/User/_types_/User';
import {
  useFetchOrderTypeFilters,
  useFetchProductLines,
  useFetchVendors,
} from 'Store/actions/dataCache.actions';
import { toastActions } from 'Store/actions/toast.actions';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';
import SelectDefault from 'UserAuthentication/Functionalities/SelectDefault';
import useAutoLogin from 'UserAuthentication/Login/LoginButton/helpers/useAutoLogin';
import { useFetchGetLinkedProfiles } from 'UserAuthentication/Login/LoginButton/helpers/useFetchGetLinkedProfiles';
import { useHandleCrossTabAuth } from 'UserAuthentication/Login/LoginButton/helpers/useHandleCrossTabAuth';
import LoginScreen from 'UserAuthentication/Login/LoginScreens';
import ErrorPage404 from 'Views/ErrorPages/ErrorPage404';
import { useRoutes as usePrivateRoutes } from 'Views/routes';
import PrivateRoute from 'Views/routes/PrivateRoute';

import { DASHBOARD_TO, LOGIN_TO } from '../Constants/shared.constants';

import classes from './App.module.scss';

const generateChildRoutes = (children: Record<string, any>): ReactNode => {
  return Object.keys(children).map((childKey) => {
    const childValue = children[childKey];
    if (!childValue.to) {
      return <></>;
    }
    return (
      <Route
        key={childKey}
        path={childValue.to}
        element={<PrivateRoute>{childValue.component}</PrivateRoute>}
        caseSensitive
      />
    );
  });
};

const generateRoutes = (privateRoutes: Record<string, any>): ReactNode => {
  return Object.entries(privateRoutes).map(([key, value]) => {
    const children = value.children ? generateChildRoutes(value.children) : null;
    return (
      <React.Fragment key={key}>
        {children}
        <Route path={value.to} element={<PrivateRoute>{value.component}</PrivateRoute>} />
      </React.Fragment>
    );
  });
};

const Redirect = ({ to }: { to: string }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  });
  return null;
};

const App = (): ReactElement => {
  useAxiosInterceptors();

  const [proformaState, proformaDispatch] = useSampleProformaHeaderReducer();
  useFetchGetLinkedProfiles();
  useHandleCrossTabAuth();

  const memorizedValue = useMemo(
    () => ({ state: proformaState, dispatch: proformaDispatch }),
    [proformaState, proformaDispatch],
  );
  const [authLoaded, setAuthLoaded] = useState(false);
  useDDAuth();
  const fetchVendors = useFetchVendors();
  const fetchProductLines = useFetchProductLines();
  const fetchOrderTypeFilters = useFetchOrderTypeFilters();
  const userAuth = useAuthState().authState;
  const dispatch = useReduxDispatch();

  const flags = useLDAuth();
  const user = useSelector((root: RootReducerState): User | null => root.userReducer.user);
  const privateRoutes = usePrivateRoutes();

  const { automaticSignInState, tryToAutomaticallySignIn } = useAutoLogin();

  useEffect((): void => {
    const doSilentLogin = async () => {
      try {
        await tryToAutomaticallySignIn();
      } finally {
        setAuthLoaded(true);
      }
    };
    if (automaticSignInState === 'NOT_STARTED' && !authLoaded) {
      doSilentLogin();
    }
  }, [authLoaded, automaticSignInState, tryToAutomaticallySignIn]);

  useEffect(() => {
    if (userAuth.tokens?.accessToken !== undefined) {
      fetchVendors();
      fetchProductLines();
      fetchOrderTypeFilters();
    }
  }, [fetchOrderTypeFilters, fetchProductLines, fetchVendors, userAuth.tokens?.accessToken]);

  useEffect((): void => {
    if (userAuth?.errorMessage) {
      dispatch(toastActions.setErrorMessage(userAuth.errorMessage));
    }
  }, [dispatch, userAuth?.errorMessage]);

  const showSetDefaultProfileModal = useMemo((): boolean | undefined => {
    if (!user) {
      return false;
    }
    return !user.hasDefaultUser;
  }, [user]);

  const [openDefaultProfileModal, setOpenDefaultProfileModal] = useState(false);

  useEffect((): void => {
    if (showSetDefaultProfileModal) {
      setOpenDefaultProfileModal(true);
    }
  }, [showSetDefaultProfileModal]);

  const routes = useMemo((): ReactElement => {
    return (
      <Routes>
        <Route path={LOGIN_TO} element={<LoginScreen />} />
        {generateRoutes(privateRoutes)}
        <Route path="/" element={<Redirect to={DASHBOARD_TO} />} />
        <Route path="*" element={<ErrorPage404 />} />
      </Routes>
    );
  }, [privateRoutes]);

  if (!authLoaded) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
        <Loading message="Trying to log you in..." />
      </Box>
    );
  }
  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <div>
            <div className={classes.content}>
              <Splash fromSuspense />
            </div>
            <VpFooter />
          </div>
        }
      >
        {showSetDefaultProfileModal && (
          <SelectDefault
            open={openDefaultProfileModal}
            setOpen={(value: boolean) => setOpenDefaultProfileModal(value)}
          />
        )}
        {!flags ? (
          <>
            <span>Waiting for flags</span>
            <Splash />
          </>
        ) : (
          <div
            className={classes.container}
            style={{
              ...(user !== null ? { flexDirection: 'row' } : { flexDirection: 'column' }),
            }}
          >
            {userAuth?.tokens?.accessToken !== undefined && <GreetingDialogs />}
            <Navigation />

            <main
              className={classes.main}
              style={{
                ...{
                  [theme.breakpoints.up('sm')]: {
                    maxWidth: 'calc(100vw - 72px)',
                  },
                },
                marginBottom: 0,
              }}
            >
              <div
                className={classes.toolbar}
                style={{
                  // NOTE: necessary for content to be below app bar
                  ...{
                    ...theme.mixins.toolbar,
                  },
                }}
              />
              <Campaign />
              <SampleProformaHeaderContext.Provider value={memorizedValue}>
                <div className={classes.content}>
                  <Papskilt />
                  <PromptToRefresh />
                  {!authLoaded && <div>Auth not loaded...</div>}
                  {authLoaded && routes}
                </div>
              </SampleProformaHeaderContext.Provider>
              <DisplayErrorHandler />
            </main>
            <VpFooter />
          </div>
        )}
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
