import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { LOGOUT } from 'Hooks/useAuthHandler/_constants_/authHandlerConstants';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import useAutoLogin from 'UserAuthentication/Login/LoginButton/helpers/useAutoLogin';

export const useHandleCrossTabAuth = () => {
  const path = useLocation();
  const navigate = useNavigate();
  const [redirectUrl, setRedirectUrl] = useState('/');
  const { authDispatch } = useAuthState();

  const { automaticSignInState, tryToAutomaticallySignIn } = useAutoLogin({
    redirectToHome: false,
  });
  const [handleReLogin, setHandleReLogin] = useState(false);

  useEffect(() => {
    if (automaticSignInState === 'COMPLETED' && handleReLogin) {
      setHandleReLogin(false);
      navigate(redirectUrl);
    }
  }, [automaticSignInState, handleReLogin, path, redirectUrl, navigate]);

  useEffect(() => {
    const handleInvalidToken = (e: StorageEvent) => {
      if (e.key === 'msal.account.keys') {
        // If the key is removed, the user has logged out from another tab
        if (e.oldValue && !e.newValue && automaticSignInState !== 'PENDING') {
          setRedirectUrl(path.pathname + path.search);
          authDispatch({ type: LOGOUT });
        }
        // If the key is added, the user has logged in from another tab
        if (!e.oldValue && e.newValue && automaticSignInState !== 'PENDING') {
          setHandleReLogin(true);
          tryToAutomaticallySignIn();
        }
      }
    };
    window.addEventListener('storage', handleInvalidToken);

    return function cleanup() {
      window.removeEventListener('storage', handleInvalidToken);
    };
  }, [authDispatch, automaticSignInState, path.pathname, path.search, tryToAutomaticallySignIn]);
};
