import { ReactElement, useState, MouseEvent, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Link, useLocation } from 'react-router-dom';

import { Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useMsal } from '@azure/msal-react';
import { Loading } from '@bestseller-bit/frontend-community.components.loading';
import { useGetFeatureFlag } from 'FeatureFlags/FeatureFlags';
import { LOGOUT } from 'Hooks/useAuthHandler/_constants_/authHandlerConstants';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { UserReducerType } from 'Models/User/_types_/UserReducerType';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';
import { DataCacheState, Vendor } from 'Store/reducers/dataCache.reducer';
import { revokeAccess } from 'UserAuthentication/_LoginUserServices/hooks/authentication/revokeAccess';
import { Profile } from 'UserAuthentication/Functionalities/ChooseProfile/_types/ProfileResponse';
import ChooseProfileMenu from 'UserAuthentication/Functionalities/ChooseProfile/ChooseProfileMenu';

import classes from './UserInfo.module.scss';

const StylizedButtonForMenu = (props: {
  label: string;
  handleClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}): ReactElement => {
  return (
    <Button
      sx={{ textTransform: 'capitalize', color: 'black', p: 0, justifyContent: 'flex-start' }}
      onClick={props.handleClick}
    >
      {props.label}
    </Button>
  );
};

export const UserInfo = (): ReactElement => {
  const { instance } = useMsal();

  const { authDispatch } = useAuthState();
  const { authState } = useAuthState();

  const [anchorAccount, setAnchorAccount] = useState<null | HTMLElement>(null);
  const open = useMemo((): boolean => {
    return Boolean(anchorAccount);
  }, [anchorAccount]);
  const handleAccountClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorAccount(event.currentTarget);
    },
    [setAnchorAccount],
  );
  const handleAccountClose = useCallback(() => {
    setAnchorAccount(null);
  }, [setAnchorAccount]);
  const user = useSelector((root: RootReducerState): UserReducerType => root.userReducer);
  const dataCacheState = useSelector(
    (root: RootReducerState): DataCacheState => root.dataCacheState,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();
  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const [loadingSwitchingAccount, setLoadingSwitchingAccount] = useState(false);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const tokens = authState.tokens;

  const stateAdAccounts = useMemo((): Profile[] => {
    return authState.profiles ?? [];
  }, [authState.profiles]);

  const getFeatureFlag = useGetFeatureFlag();
  const flag = useFlags();

  const shouldRenderADFeatures = useMemo((): boolean => {
    if (flag[getFeatureFlag('multiple-ad-users')] === false) {
      return false;
    }
    if (stateAdAccounts.length === 0 || user.user?.isBestsellerUser || tokens?.provider !== 'AD') {
      return false;
    }
    return true;
  }, [flag, getFeatureFlag, stateAdAccounts.length, tokens?.provider, user.user?.isBestsellerUser]);

  const shouldRenderSwitchAccount = useMemo(
    (): boolean => Boolean(shouldRenderADFeatures && stateAdAccounts.length > 1),
    [shouldRenderADFeatures, stateAdAccounts.length],
  );

  if (!user?.user || !user.user.fullName) {
    return <></>;
  }
  const splitted = user.user.fullName.split(' ');
  const first = splitted[0][0];
  const second = splitted.length > 1 ? splitted[1][0] : '';

  if (loadingSwitchingAccount) {
    return (
      <Box p={1}>
        <Loading circularProgressColor="white" />
      </Box>
    );
  }

  return (
    <>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <Avatar>
          {first}
          {second}
        </Avatar>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ list: classes.menu }}
      >
        <div className={[classes.menuItem, classes.nameContainer].join(' ')}>
          {user.user.fullName}
          <div>
            {user.user.canChangeVendorMdmIdentifier && (
              <div className={classes.vendorInfo}>
                <span>Vendor:</span>{' '}
                <span>
                  {dataCacheState.vendors.loading ? (
                    <span>Loading...</span>
                  ) : (
                    (dataCacheState.vendors.vendors.find(
                      (x: Vendor): boolean =>
                        x.vendorMdmIdentifier === user.user?.vendorMdmIdentifier,
                    )?.vendorName ?? <span>All vendors</span>)
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
        {user.user.canChangeVendorMdmIdentifier && (
          <MenuItem onClick={handleClose} classes={{ root: classes.linkContainer }}>
            <NavLink
              to={{ pathname: '/setvendor' }}
              className={classes.menuItem}
              state={{ from: location.pathname }}
            >
              <StylizedButtonForMenu label="Set vendor" />
            </NavLink>
          </MenuItem>
        )}
        {shouldRenderSwitchAccount && (
          <MenuItem classes={{ root: classes.linkContainer }}>
            <>
              {' '}
              <Box className={classes.menuItem}>
                <StylizedButtonForMenu label="Switch account" handleClick={handleAccountClick} />
              </Box>
              <Menu
                anchorEl={anchorAccount}
                open={open}
                onClose={handleAccountClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <ChooseProfileMenu
                  closeAccount={handleClose}
                  closeAccountMenu={handleAccountClose}
                  setLoadingSwitchAccount={(value: boolean) => setLoadingSwitchingAccount(value)}
                />
              </Menu>
            </>
          </MenuItem>
        )}
        {shouldRenderADFeatures && (
          <MenuItem onClick={handleClose} classes={{ root: classes.linkContainer }}>
            <Link
              to="/manageaccounts"
              state={{ from: location.pathname }}
              className={classes.menuItem}
            >
              <StylizedButtonForMenu label="Manage accounts" />
            </Link>
          </MenuItem>
        )}
        <MenuItem onClick={handleClose} classes={{ root: classes.linkContainer }}>
          {tokens?.provider !== 'AD' ? (
            <div
              style={{ color: 'black', textDecoration: 'none' }}
              className={classes.menuItem}
              onClick={(): void => {
                if (tokens?.accessToken) {
                  revokeAccess(tokens?.accessToken);
                }
                authDispatch({ type: LOGOUT });
              }}
            >
              <StylizedButtonForMenu label="Logout" />
            </div>
          ) : (
            <div
              style={{ color: 'black', textDecoration: 'none' }}
              className={classes.menuItem}
              onClick={(): void => {
                instance.logout();
                sessionStorage.clear();
                authDispatch({ type: LOGOUT });
              }}
            >
              <StylizedButtonForMenu label="Logout from AD" />
            </div>
          )}
        </MenuItem>
      </Menu>
    </>
  );
};
