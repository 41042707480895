import axios from 'axios';

import { BestoneResponse } from 'Services/ext.service/interfaces/BestoneFileResponse';
import { userServiceApiBaseUrl } from 'UserAuthentication/_LoginUserServices/userService';
import { Profile } from 'UserAuthentication/Functionalities/ChooseProfile/_types/ProfileResponse';

const GET_LINK_ACCOUNTS = userServiceApiBaseUrl(`profiles`);

export const getLinkedProfiles = (token: string): Promise<BestoneResponse<Profile[]>> => {
  const cleanAxios = axios.create();
  return cleanAxios.get(GET_LINK_ACCOUNTS, {
    headers: {
      'USERS-API-VERSION': '2.0',
      authorization: `Bearer ${token}`,
    },
  });
};
