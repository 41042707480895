import { UseLazyBestApiTuple, useLazyBestApi } from 'Components/Hooks/useLazyBestApi';
import {
  PutLinkedAccount,
  userServiceApiBaseUrl,
} from 'UserAuthentication/_LoginUserServices/userService';

const PUT_DEFAULT_PROFILE = (bi2NetworkId: string) =>
  userServiceApiBaseUrl(`profile/${bi2NetworkId}/set-default`);

// Generated by https://quicktype.io

export interface PutDefaultProfileResponse {
  bi2NetworkId: string;
  bi2UsersId: number;
  email: string;
  displayName: string;
  isDefault: string;
  companyName: string;
  mdmIdentifier: string;
  currentUser: boolean;
}

export const usePutDefaultProfile = (
  bi2NetworkId: string,
): UseLazyBestApiTuple<PutDefaultProfileResponse, PutLinkedAccount> => {
  return useLazyBestApi<PutDefaultProfileResponse, PutLinkedAccount>(
    PUT_DEFAULT_PROFILE(bi2NetworkId),
    {
      headers: {
        'USERS-API-VERSION': '2.0',
      },
      method: 'PUT',
    },
  );
};
