import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Alert, MenuItem, Tooltip } from '@mui/material';

import axios, { AxiosResponse } from 'axios';
import { useReduxDispatch } from 'Helpers';
import { v4 as uuidv4 } from 'uuid';

import { Loading } from '@bestseller-bit/frontend-community.components.loading';
import { WebToken } from '_types_/Token';
import { cutStringIfNeeded } from 'Helpers/cutStringIfNeeded';
import { LOGOUT, SET_TOKENS } from 'Hooks/useAuthHandler/_constants_/authHandlerConstants';
import { Token } from 'Hooks/useAuthHandler/state/_types/Token';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { User } from 'Models/User/_types_/User';
import { userActions } from 'Store/actions/user.actions';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';
import { getProfile } from 'UserAuthentication/_LoginUserServices/hooks/authentication/getProfile';
import { MAP_TOKEN } from 'UserAuthentication/_LoginUserServices/hooks/authentication/mapMicrosoftTokenToProfileToken';
import { usePutDefaultProfile } from 'UserAuthentication/_LoginUserServices/hooks/functionalities/usePutDefaultProfile';
import { Profile } from 'UserAuthentication/Functionalities/ChooseProfile/_types/ProfileResponse';

export const PROFILE_TABLE_NAME = 'Choose Account';

const ChooseProfileMenu = (props: {
  closeAccount: () => void;
  closeAccountMenu: () => void;
  setLoadingSwitchAccount?: (loading: boolean) => void;
}): ReactElement => {
  const { authState } = useAuthState();

  const userProfiles = useMemo((): Profile[] => {
    return authState.profiles ?? [];
  }, [authState.profiles]);

  const { closeAccountMenu, closeAccount } = props;
  const navigate = useNavigate();
  const dispatch = useReduxDispatch();
  const { authDispatch } = useAuthState();
  const user = useSelector((root: RootReducerState): User | null => root.userReducer.user);

  const [selectedAccount, setSelectedAccount] = useState<string>('');
  const [fetchPutDefaultAccount, { loading: loadingPutDefault, data }] =
    usePutDefaultProfile(selectedAccount);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [hasFetch, setHasFetch] = useState(false);

  useEffect(() => {
    if (data === undefined || loadingPutDefault) {
      return;
    }
    dispatch(userActions.updateUserMdM(data.mdmIdentifier));
  }, [data, dispatch, loadingPutDefault]);

  const handleSwitchAccount = useCallback(
    async (bi2NetworkId: string): Promise<Token | undefined> => {
      setLoading(true);
      const data = new URLSearchParams();
      data.append('grant_type', 'password');
      data.append('client_id', 'bff-clientid');
      data.append('NETWORK_ID', bi2NetworkId);

      return axios
        .post(MAP_TOKEN, data, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'USERS-API-VERSION': '2.0',
          },
        })
        .then(async (res: AxiosResponse<WebToken>) => {
          const newToken: Token = {
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token,
            expires: new Date().getTime() + 1000 * res.data.expires_in,
            provider: 'AD',
            idToken: res.data.access_token,
            remember: false,
            sid: uuidv4(),
            hasADLogin: true,
            email: res.data.email,
            inviteStatus: res.data.invite_status,
          };
          return newToken;
        })
        .catch((e) => {
          setError(e.message);
          return undefined;
        });
    },
    [setError, setLoading],
  );

  const awaitRefresh = useCallback(() => {
    return new Promise(() => {
      setTimeout(() => {
        if (props.setLoadingSwitchAccount) {
          props.setLoadingSwitchAccount(false);
        }
        navigate('/');
      }, 2000);
    });
  }, [navigate, props]);

  const fetchUser = useCallback(
    async (user: User, token: Token) => {
      fetchPutDefaultAccount()
        .then(() => {
          authDispatch({ type: SET_TOKENS, tokens: token });
          dispatch(userActions.setUser(user));
        })
        .finally(() => {
          awaitRefresh();
        });
      return user;
    },
    [awaitRefresh, dispatch, fetchPutDefaultAccount, authDispatch],
  );

  useEffect(() => {
    if (selectedAccount && !hasFetch) {
      if (props.setLoadingSwitchAccount) {
        props.setLoadingSwitchAccount(true);
      }
      handleSwitchAccount(selectedAccount)
        .then((token) => {
          if (!token) {
            return;
          }
          dispatch({ type: LOGOUT });
          getProfile(token).then(async (user) => {
            if (user !== undefined) {
              await fetchUser(user, token);
            }
          });
          setLoading(false);
          closeAccountMenu();
          closeAccount();
          setHasFetch(true);
        })
        .catch((e) => {
          setError(e.message);
          setLoading(false);
        });
    }
  }, [
    closeAccount,
    closeAccountMenu,
    fetchUser,
    handleSwitchAccount,
    hasFetch,
    props,
    selectedAccount,
    dispatch,
  ]);

  const handleClick = useCallback(
    (bi2NetworkId: string) => {
      setSelectedAccount(bi2NetworkId);
    },
    [setSelectedAccount],
  );

  const isLoading = useMemo(() => loading || loadingPutDefault, [loading, loadingPutDefault]);

  if (!user) {
    return <div>Something went wrong</div>;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: isLoading ? 'lightgrey' : 'transparent',
        display: 'block',
      }}
    >
      {isLoading && (
        <div style={{ position: 'absolute', left: '40%', top: '40%' }}>
          <Loading />
        </div>
      )}
      {userProfiles.map((row) => {
        if (!row.companyName) {
          return null;
        }
        const displayName = cutStringIfNeeded(row.companyName, 25);
        return (
          <Tooltip
            key={row.bi2NetworkId}
            title={row.companyName.length < 25 ? '' : row.companyName}
            placement="top"
          >
            <MenuItem
              key={row.bi2NetworkId}
              onClick={() => handleClick(row.bi2NetworkId)}
              disabled={isLoading || row.mdmIdentifier === user.vendorMdmIdentifier}
            >
              {displayName}
            </MenuItem>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default ChooseProfileMenu;
