import axios from 'axios';
import { basicAuthToken, clientSecret } from 'Helpers';

import { loginServiceApiBaseUrl } from 'UserAuthentication/_LoginUserServices/loginService';

const CHECK_CREDENTIALS = (beBranch?: string) =>
  loginServiceApiBaseUrl('accesstokenprovider/oauth/token', beBranch);

export const checkProfileCredentials = (
  username: string,
  password: string,
  beBranch: string | undefined,
): Promise<
  | {
      data: {
        access_token: string;
        token_type: string;
        refresh_token: string;
        expires_in: number;
        scope: string;
        adInfo: {
          hasADLogin: boolean;
          adlogin: boolean;
        };
        email: string;
        invite_status: string | undefined;
      };
    }
  | undefined
> => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Basic ${basicAuthToken()}`,
  };

  const data = new URLSearchParams();
  data.append('username', username);
  data.append('password', password);
  data.append('grant_type', 'password');
  data.append('client_secret', clientSecret);
  data.append('client_id', 'bff-clientid');

  const instance = axios.create();

  return instance
    .post(CHECK_CREDENTIALS(beBranch), data, {
      headers,
    })
    .then((response) => {
      return response;
    })
    .catch(() => {
      return undefined;
    });
};
