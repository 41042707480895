import axios, { AxiosError, AxiosResponse } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';

import { datadogRum } from '@datadog/browser-rum';
import { WebToken } from '_types_/Token';
import { Token } from 'Hooks/useAuthHandler/state/_types/Token';
import { loginServiceApiBaseUrl } from 'UserAuthentication/_LoginUserServices/loginService';

export const MAP_TOKEN = loginServiceApiBaseUrl('accesstokenprovider/user/mapToken');

export interface MapTokenError extends Error {
  code: string | undefined;
  token: string;
}

export const mapMicrosoftTokenToProfileToken = (token: string): Promise<Token | undefined> => {
  const data = new URLSearchParams();
  data.append('grant_type', 'password');
  data.append('client_id', 'bff-clientid');
  const parsedToken = jwtDecode<{ email: string; name: string }>(token);
  datadogRum.setUserProperty('email', parsedToken?.email ?? '');
  datadogRum.setUserProperty('id', 'AD-' + (parsedToken?.email ?? ''));
  datadogRum.setUserProperty('name', parsedToken?.name ?? '');
  datadogRum.addAction('Mapping token!');

  const cleanAxios = axios.create();

  return cleanAxios
    .post(MAP_TOKEN, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + token,
        Provider: 'AD',
        'USERS-API-VERSION': '2.0',
      },
    })
    .then((res: AxiosResponse<WebToken>) => {
      const newToken: Token = {
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
        idToken: token,
        expires: new Date().getTime() + 1000 * res.data.expires_in,
        provider: 'AD',
        sid: uuidv4(),
        remember: false,
        hasADLogin: res.data.adInfo.hasADLogin,
        email: res.data.email,
        inviteStatus: undefined,
      };
      return newToken;
    })
    .catch((e: AxiosError) => {
      const error: MapTokenError = {
        message: e.message,
        token: token,
        name: 'MapTokenError',
        code: e.code,
      };
      datadogRum.addError(`Error mapping token ${token} ${JSON.stringify(e, null)}`, e);
      throw error;
    });
};
