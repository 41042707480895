import axios from 'axios';

import { OnboardUser } from 'Models/User/_types_/User';
import { userServiceApiBaseUrl } from 'UserAuthentication/_LoginUserServices/userService';

const SEND_FIRST_TIME_INVITATION = userServiceApiBaseUrl('onboard');
const SEND_INVITATION_FOR_CHANGE_EMAIL = userServiceApiBaseUrl('change-email');

export const sendMicrosoftInvitation = (
  email: string,
  authToken: string,
  isChangeEmail?: boolean,
): Promise<OnboardUser | undefined> => {
  if (isChangeEmail) {
    return axios.put(
      SEND_INVITATION_FOR_CHANGE_EMAIL,
      { email: email },
      {
        headers: {
          Authorization: 'Bearer ' + authToken,
          'USERS-API-VERSION': '2.0',
        },
      },
    );
  }
  return axios.post(
    SEND_FIRST_TIME_INVITATION,
    {
      email: email,
    },
    {
      headers: {
        Authorization: 'Bearer ' + authToken,
        'USERS-API-VERSION': '2.0',
      },
    },
  );
};
