import { useReduxDispatch } from 'Helpers';

import { datadogRum } from '@datadog/browser-rum';
import { LOGOUT, SET_TOKENS } from 'Hooks/useAuthHandler/_constants_/authHandlerConstants';
import { Token } from 'Hooks/useAuthHandler/state/_types/Token';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { User } from 'Models/User/_types_/User';
import { userActions } from 'Store/actions/user.actions';
import { getProfile } from 'UserAuthentication/_LoginUserServices/hooks/authentication/getProfile';
import { mapMicrosoftTokenToProfileToken } from 'UserAuthentication/_LoginUserServices/hooks/authentication/mapMicrosoftTokenToProfileToken';

export const useLogin = (): (({
  adIdToken,
}: {
  adIdToken?: string;
}) => Promise<User | undefined>) => {
  const dispatch = useReduxDispatch();
  const { authDispatch } = useAuthState();

  return async ({ adIdToken: adToken }: { adIdToken?: string }): Promise<User | undefined> => {
    if (adToken === undefined) {
      authDispatch({ type: LOGOUT });
      dispatch(userActions.deleteUser());
      return Promise.reject(new Error('Token is undefined'));
    }

    let bi2Token: Token | undefined;

    try {
      bi2Token = await mapMicrosoftTokenToProfileToken(adToken);
      if (bi2Token === undefined) {
        dispatch(userActions.deleteUser());
        authDispatch({ type: LOGOUT });
        throw new Error('bi2Token is undefined');
      }
    } catch (e) {
      dispatch(userActions.deleteUser());
      authDispatch({ type: LOGOUT });
      throw e;
    }

    authDispatch({ type: SET_TOKENS, tokens: bi2Token });

    try {
      const user = await getProfile(bi2Token);
      if (user !== undefined) {
        dispatch(userActions.setUser(user));
        return user;
      }
    } catch {
      datadogRum.addError('Refresh token failed.');
      authDispatch({ type: LOGOUT });
      return undefined;
    }
  };
};
