import { useCallback, useEffect, useRef, useState } from 'react';
import { matchPath, useMatches } from 'react-router-dom';

import { getAllRoutesFlat, useRoutes } from 'Views/routes';

import { BreadCrumb } from './_types_';

export * from './_types_';

/**
 * Returns an array of breadcrumbs which get updated when the window location changes.
 * It looks in the `routes` object in `UI/Navigation/routes`
 * @returns
 *
 * ```
 * [{
 *    label: string,
 *    to: string | null,
 * }]
 * ```
 *
 *  @example
 *
 * ```
 * const breadCrumbs: BreadCrumb[] = useBreadCrumbs();
 * ```
 *
 */

export const useBreadCrumbs = (): BreadCrumb[] => {
  const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumb[]>([]);
  const routes = useRef(useRoutes());
  const matches = useMatches();
  const getBreadCrumbs = useCallback(() => {
    const returnedBreadCrumbs: BreadCrumb[] = [];

    matches.forEach((m) => {
      const flat = getAllRoutesFlat(routes.current);

      const route = flat.find((r) =>
        r.to
          ? matchPath(
              {
                path: r.to,
                end: true,
              },
              m.pathname,
            )
          : false,
      );
      if (route?.to) {
        let label = route.label;
        Object.keys(m.params).forEach((key) => {
          label = label.replace(`:${key}`, m.params[key] ?? '');
        });
        returnedBreadCrumbs.push({
          label,
          to: route.to || null,
        });
      }
    });

    return returnedBreadCrumbs;
  }, [matches]);

  useEffect(() => {
    setBreadCrumbs([...getBreadCrumbs()]);
  }, [getBreadCrumbs]);

  return breadCrumbs;
};
