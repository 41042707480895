import axios from 'axios';

import { loginServiceApiBaseUrl } from 'UserAuthentication/_LoginUserServices/loginService';

const revokeAccessUrl = loginServiceApiBaseUrl('accesstokenprovider/user/revokeUserAccess');

export const revokeAccess = (token: string): Promise<void> => {
  return axios.delete(revokeAccessUrl + '/' + token, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
};
