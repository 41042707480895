import axios, { AxiosResponse } from 'axios';

import { datadogRum } from '@datadog/browser-rum';
import { Token } from 'Hooks/useAuthHandler/state/_types/Token';
import { User } from 'Models/User/_types_/User';
import { BestoneResponse } from 'Services/ext.service/interfaces/BestoneFileResponse';
import { userServiceApiBaseUrl } from 'UserAuthentication/_LoginUserServices/userService';

const GET_PROFILE = userServiceApiBaseUrl('info');

const getIsBestsellerUser = (user: User): boolean => {
  if (user.userType === 'BESTSELLER') {
    return true;
  }

  if (user.canChangeVendorMdmIdentifier && user.canHaveEmptyVendorMdmIdentifier) {
    return true;
  }

  return false;
};

export const getProfile = (token: Token): Promise<User | undefined> => {
  const cleanAxios = axios.create({
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      Authorization: `Bearer ${token.accessToken}`,
      provider: 'basic',
      'USERS-API-VERSION': '2.0',
    },
  });

  return cleanAxios
    .get<User>(GET_PROFILE)
    .then((user: AxiosResponse<User> | AxiosResponse<BestoneResponse<User>>): User | undefined => {
      try {
        const newUserRequest = (user as AxiosResponse<BestoneResponse<User>>).data.data;
        const oldUserRequest = user as AxiosResponse<User>;

        if (newUserRequest) {
          if (!newUserRequest.usersId) {
            throw new Error('ERROR');
          }
          newUserRequest.hasADLogin = token.hasADLogin; // setting this field until BE userLoggedIn call is updated and matches token response
          newUserRequest.adEmail = token.email;
          newUserRequest.adEmail = token.inviteStatus;
          newUserRequest.isBestsellerUser = getIsBestsellerUser(newUserRequest);
          return newUserRequest;
        }

        if (oldUserRequest) {
          if (!oldUserRequest.data?.usersId) {
            throw new Error('ERROR');
          }
          oldUserRequest.data.hasADLogin = token.hasADLogin;
          oldUserRequest.data.adEmail = token.email;
          oldUserRequest.data.inviteStatus = token.inviteStatus;
          oldUserRequest.data.isBestsellerUser = getIsBestsellerUser(oldUserRequest.data);

          return oldUserRequest.data;
        }
      } catch (e) {
        datadogRum.addError('Login error. User could not be casted.', e ?? 'Unknown error');
        throw new Error('ERROR');
      }
    });
};
